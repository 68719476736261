<template>
  <vertical-modal>
    <div class="flex flex-col py-20 px-10">
      <h2 class="font-bold mb-3">#{{ item.id }}</h2>
      <div class="flex flex-col">
        <div class="grid grid-cols-3 py-5 border-b-2 border-salmon border-opacity-30 w-full">
          <div class="text-black text-opacity-70">Data</div>
          <div class="col-start-2 col-end-3 text-black">
            {{ getDate(item.date).format('DD/MM/YYYY HH:mm') }}
            <span class="text-red-500 uppercase text-xs mt-5" v-if="item.canceled_at">Cancelada</span>
          </div>
        </div>

        <div class="grid grid-cols-3 border-b-2 border-salmon border-opacity-30 py-5 w-full" v-if="item.user">
          <div class="text-black text-opacity-70">Usuário</div>
          <div class="col-start-2 col-end-3">
            <div class="text-black text-opacity-70">{{ item.user.name }}</div>
            <div class="text-black text-sm text-opacity-50">{{ item.user.email }}</div>
          </div>
        </div>

        <div class="grid grid-cols-3 border-b-2 border-salmon border-opacity-30 py-5 w-full">
          <div class="text-black text-opacity-70">Avaliação</div>
          <div class="col-start-2 col-end-3">
            <rating :value="item.review" />
          </div>
        </div>

        <div class="grid grid-cols-3 border-b-2 border-salmon border-opacity-30 py-5 w-full">
          <div class="text-black text-opacity-70">Consultora</div>
          <div class="col-start-2 col-end-3">
            <div class="text-black text-opacity-70">
              <template v-if="item.consultant">
                {{ item.consultant.name }}
              </template>

              <template v-else>
                <i>Consultora Deletada</i>
              </template>
            </div>

            <div class="text-black text-sm text-opacity-50">
              <template v-if="item.consultant">
                {{ item.consultant.email }}
              </template>

              <template v-else></template>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-3 py-5 border-b-2 border-salmon border-opacity-30 w-full">
          <div class="text-black text-opacity-70">Duração</div>
          <div class="col-start-2 col-end-3 text-black">{{ duration === '0:00' ? '-' : duration }}</div>
        </div>

        <div class="recording-files grid grid-cols-3 border-b-2 border-salmon border-opacity-30 py-5 w-full" >
          <div class="text-black text-opacity-70">Gravação</div>
          <div class="col-start-2 col-end-3">
            <div class="text-black text-opacity-70" v-if="item.twilio_composition_sid && item.twilio_composition_sid !== 'x'">
              Video

              <ul class="recording-files__list mt-2">
                <li class="text-blue" >
                  <div class="relative flex items-center justify-start mt-2">
                    <span class="block w-16 text-sm mr-6">{{ duration === '0:00' ? '-' : duration }}</span>

                    <svg v-if="item.twilio_composition_sid && item.twilio_composition_sid !== 'x'" width="20" height="14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 3v8H3V3h10zm1-2H2c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1V8.5l4 4v-11l-4 4V2c0-.55-.45-1-1-1z" fill="#00136F" stroke="#fff" stroke-width=".5"/></svg>

                    <a href="#" class="absolute top-0 left-0 w-full h-full flex items-center justify-center outline-none"
                      @click="downloadVideo()"></a>
                  </div>
                </li>
              </ul>
            </div>

           <!-- <div class="text-black text-opacity-70 mt-8" v-if="recordingsUser.length">
              Usuária

              <ul class="recording-files__list mt-2">
                <li class="text-blue" v-for="recording in recordingsUser" :key="recording.sid">
                  <div class="relative flex items-center justify-start mt-2">
                    <span class="block w-16 text-sm mr-6">{{ recordingDuration(recording) }}</span>

                    <svg v-if="recording.type === 'video'" width="20" height="14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 3v8H3V3h10zm1-2H2c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h12c.55 0 1-.45 1-1V8.5l4 4v-11l-4 4V2c0-.55-.45-1-1-1z" fill="#00136F" stroke="#fff" stroke-width=".5"/></svg>
                    <svg v-if="recording.type === 'audio'" width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 7v6h4l5 5V2L5 7H1zm7-.17v6.34L5.83 11H3V9h2.83L8 6.83zM14.5 10A4.5 4.5 0 0012 5.97v8.05c1.48-.73 2.5-2.25 2.5-4.02zM12 1.23v2.06c2.89.86 5 3.54 5 6.71s-2.11 5.85-5 6.71v2.06c4.01-.91 7-4.49 7-8.77 0-4.28-2.99-7.86-7-8.77z" fill="#00136F" stroke="#fff" stroke-width=".5"/></svg>

                    <a href="#" class="absolute top-0 left-0 w-full h-full flex items-center justify-center outline-none"
                      @click="downloadFile(recording)"></a>
                  </div>
                </li>
              </ul>
            </div>-->
          </div>
        </div>

        <loader :isloading="loading" />
      </div>
    </div>
  </vertical-modal>
</template>

<script>
import api from '@/utils/api.js';
import dayjs from 'dayjs';
import { mapState } from 'vuex';

import VerticalModal from '@/components/VerticalModal';
import Rating from '@/components/Rating.vue';
import { getDurationForReservation } from '@/utils/schedule.js';
import Loader from '@/components/Loader';

export default {
  name: "AdminUser",
  components: {
    Loader,
    Rating,
    VerticalModal,
  },
  props: ['item', 'loading'],
  computed: {
    ...mapState('verticalModal', ['open']),

    duration() {
      return getDurationForReservation(this.item);
    },

    recordingsConsultant() {
      return (this.item.recordings || [])
        .filter( (item) => item.participant_type === 'consultant').sort((a, b) => (a.type < b.type) ? 1 : -1);
    },

    recordingsUser() {
      return (this.item.recordings || [])
        .filter( (item) => item.participant_type === 'user').sort((a, b) => (a.type < b.type) ? 1 : -1);
    },
  },
  methods: {
    getDate(date) {
      return dayjs(date);
    },
    async downloadFile(recording) {
      const { data } = await api.get( `reservation/${this.item.id}/recording/${recording.sid}/download`);
      window.open(data);
    },
    async downloadVideo() {
      const { data } = await api.get( `reservation/${this.item.id}/composition/${this.item.twilio_composition_sid}/download`);
      window.open(data.url);
    },
    recordingDuration(recording) {
      return new Date(recording.duration * 1000).toISOString().substr(11, 8);
    },
  }
};
</script>

<style lang="scss"></style>
