<template>
  <main class="products admin-page bg-cream px-12 py-12 ml-auto">
    <div class="container">
      <header class="admin-page-header flex items-center mb-10">
        <h1 class="text-3xl text-blue">Histórico de consultas</h1>
        <search v-model="search" />
      </header>

      <div class="historic-table admin-data-table bg-white rounded-lg pb-12">
        <v-data-table
          :headers="headers"
          :items="items"
          hide-default-footer
          disable-pagination
          class="elevation-1"
          :loading="loading"
          loading-text="Carregando... Aguarde"
          @click:row="openPreview"
        >
          <template v-slot:item.date="{ item }">
            {{ getDate(item.date).format('DD/MM/YYYY') }}
            <span class="block text-xs opacity-60">{{ getDate(item.date).format('HH:mm') }}</span>
          </template>

          <template v-slot:item.user.name="{ item }">
            <template v-if="item.user">
              {{ item.user.name }}
              <span class="block text-xs opacity-60">{{ item.user.email }}</span>
            </template>
            <i v-else>Indefinido</i>
          </template>

          <template v-slot:item.consultant="{ item }">
            <template v-if="item.consultant">
              {{ item.consultant.name }}
              <span class="block text-xs opacity-60">{{ item.consultant.email }}</span>
            </template>

            <template v-else>
              <i>Consultora Deletada</i>
            </template>
          </template>

          <template v-slot:item.review="{ item }">
            <rating :value="item.review" />
          </template>

          <template v-slot:item.duration="{ item }">
            {{ getDuration(item) === '0:00' ? '-' : getDuration(item) }}
          </template>

          <template v-slot:item.download="{}">
            <button class="historic-table__download flex w-8 h-8"></button>
          </template>
        </v-data-table>

        <pagination :paginator="paginator" @load="loadItems" />
      </div>
    </div>

    <modal-history-consultant :item="selectedItem" :loading="recordingsLoading" />
  </main>
</template>

<script>
import api from '@/utils/api.js';
import dayjs from 'dayjs';
import paginator from '@/utils/paginator.js';
import { getDurationForReservation } from '@/utils/schedule.js';
import Search from '@/components/admin/Search';
import Pagination from '@/components/Pagination';
import ModalHistoryConsultant from '@/components/modals/HistoryConsultant';
import Rating from '@/components/Rating.vue';

export default {
  name: "Historic",
  components: {
    Rating,
    Search,
    Pagination,
    ModalHistoryConsultant,
  },
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'Data',
          align: 'start',
          value: 'date',
        },
        { text: 'Usuária', value: 'user.name' },
        { text: 'Consultora', value: 'consultant' },
        { text: 'Avaliação', value: 'review' },
        { text: 'Duração', value: 'duration' },
        { text: '', value: 'download', sortable: false },
      ],
      items: [],
      loading: false,
      recordingsLoading: false,
      paginator: null,
      selectedItem: {
        user: {},
        consultant: {},
      }
    }
  },
  watch: {
    search() {
      this.loadItems();
    }
  },
  mounted() {
    this.loadItems();
  },
  methods: {
    async loadItems(meta) {
      this.loading = true;
      this.paginator = await paginator('reservations/list', meta, this.search);
      this.items = this.paginator.data;
      this.loading = false;
    },
    async openPreview(item) {
      this.recordingsLoading = true;

      this.selectedItem = item;
      this.$store.dispatch('verticalModal/openModal');

      const { data } = await api.get( `reservation/${item.id}/recordings`);

      this.selectedItem = {
        ...item,
        recordings: data,
      };

      this.recordingsLoading = false;
    },
    getDate(date) {
      return dayjs(date);
    },
    getDuration(item) {
      return getDurationForReservation(item);
    }
  }
};
</script>

<style lang="scss">
.historic-table {
  .v-data-table-header {
    th.text-start {
      &:last-child {
        span {
          background-image: none;
        }
      }
    }
  }
}

.historic-table__download {
  background-size: 15px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.59 7H11V2c0-.55-.45-1-1-1H6c-.55 0-1 .45-1 1v5H3.41c-.89 0-1.34 1.08-.71 1.71l4.59 4.59c.39.39 1.02.39 1.41 0l4.59-4.59c.63-.63.19-1.71-.7-1.71zM1 17c0 .55.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1H2c-.55 0-1 .45-1 1z' fill='%2300136F' stroke='%23fff' stroke-width='.5'/%3E%3C/svg%3E");

  &:focus {
    outline: none;
  }
}
</style>
