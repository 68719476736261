var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"products admin-page bg-cream px-12 py-12 ml-auto"},[_c('div',{staticClass:"container"},[_c('header',{staticClass:"admin-page-header flex items-center mb-10"},[_c('h1',{staticClass:"text-3xl text-blue"},[_vm._v("Histórico de consultas")]),_c('search',{model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"historic-table admin-data-table bg-white rounded-lg pb-12"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-footer":"","disable-pagination":"","loading":_vm.loading,"loading-text":"Carregando... Aguarde"},on:{"click:row":_vm.openPreview},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDate(item.date).format('DD/MM/YYYY'))+" "),_c('span',{staticClass:"block text-xs opacity-60"},[_vm._v(_vm._s(_vm.getDate(item.date).format('HH:mm')))])]}},{key:"item.user.name",fn:function(ref){
var item = ref.item;
return [(item.user)?[_vm._v(" "+_vm._s(item.user.name)+" "),_c('span',{staticClass:"block text-xs opacity-60"},[_vm._v(_vm._s(item.user.email))])]:_c('i',[_vm._v("Indefinido")])]}},{key:"item.consultant",fn:function(ref){
var item = ref.item;
return [(item.consultant)?[_vm._v(" "+_vm._s(item.consultant.name)+" "),_c('span',{staticClass:"block text-xs opacity-60"},[_vm._v(_vm._s(item.consultant.email))])]:[_c('i',[_vm._v("Consultora Deletada")])]]}},{key:"item.review",fn:function(ref){
var item = ref.item;
return [_c('rating',{attrs:{"value":item.review}})]}},{key:"item.duration",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDuration(item) === '0:00' ? '-' : _vm.getDuration(item))+" ")]}},{key:"item.download",fn:function(ref){return [_c('button',{staticClass:"historic-table__download flex w-8 h-8"})]}}])}),_c('pagination',{attrs:{"paginator":_vm.paginator},on:{"load":_vm.loadItems}})],1)]),_c('modal-history-consultant',{attrs:{"item":_vm.selectedItem,"loading":_vm.recordingsLoading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }